import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3631.579 1894.738">
    <g transform="matrix(31.57895 0 0 31.57895 315.79 315.79)">
      <g xmlns="http://www.w3.org/2000/svg" transform="matrix(.36 0 0 .36 0 2)" fill="#64ffda">
        <circle cx="49.494" cy="6.603" r="2.128" />
        <path d="M18.267 34.851l6.123-7.465a1.474 1.474 0 10-2.28-1.872l-6.972 8.499a1.475 1.475 0 00.098 1.979l7.593 7.591c.288.289.665.433 1.042.433a1.475 1.475 0 001.043-2.519l-6.647-6.646zM44.093 32.998L36.5 25.406a1.475 1.475 0 10-2.086 2.087l6.647 6.646-6.123 7.465a1.477 1.477 0 002.283 1.871l6.97-8.499a1.475 1.475 0 00-.098-1.978z" />
        <path d="M88.568 0H9.432C4.491 0 0 4.02 0 8.96v55.608C0 69.51 4.491 74 9.432 74h31.279c.159 2 .311 8.268-.323 10.369-1.163 1.729-13.49 5.365-24.164 7.854-.664.156-1.224.631-1.224 1.314v4.992c0 .813.835 1.471 1.647 1.471h64.707c.812 0 1.646-.658 1.646-1.471v-4.992c0-.684-.559-1.277-1.225-1.434-10.732-2.486-23.082-6.008-24.202-7.625-.677-2.201-.648-8.478-.534-10.478h31.529C93.508 74 98 69.51 98 64.568V8.96C98 4.02 93.508 0 88.568 0zM80 94.703V97H18v-2.298c11-2.638 24.097-6.121 25.095-9.362.868-2.828.701-9.34.562-11.34h10.438c-.104 2-.188 8.514.682 11.343.996 3.24 14.223 6.721 25.223 9.36zM95 64.98c0 3.326-3.695 6.02-7.02 6.02H9.02A6.018 6.018 0 013 64.98V9.019A6.02 6.02 0 019.02 3h78.96C91.305 3 95 5.695 95 9.019V64.98z" />
        <path d="M88.529 12H9.471C8.659 12 8 12.659 8 13.471v44.058A1.47 1.47 0 009.471 59h79.058A1.47 1.47 0 0090 57.529V13.471A1.47 1.47 0 0088.529 12zM11 15h37v41H11V15zm76 41H51V15h36v41z" />
        <path d="M54.5 36h29a1.5 1.5 0 001.5-1.5v-16a1.5 1.5 0 00-1.5-1.5h-29a1.5 1.5 0 00-1.5 1.5v16a1.5 1.5 0 001.5 1.5zM56 20h26v13H56V20zM83 39H55a1.5 1.5 0 100 3h28a1.5 1.5 0 100-3zM83 45H55a1.5 1.5 0 100 3h28a1.5 1.5 0 100-3zM83 51H55a1.5 1.5 0 000 3h28a1.5 1.5 0 000-3z" />
      </g>
      <path
        d="M72.143 33.613c-.84-.168-1.625-.42-2.353-.756a12.856 12.856 0 01-4.118-3.025c-1.12-1.177-2.017-2.633-2.689-4.37a15.727 15.727 0 01-.924-5.294V19.748c0-1.849.308-3.642.924-5.378.728-1.793 1.625-3.222 2.69-4.286a11.043 11.043 0 014.033-2.941c.952-.448 1.765-.728 2.437-.84V0c-1.96.28-3.698.756-5.21 1.429-2.465 1.064-4.566 2.493-6.303 4.285s-3.109 3.922-4.117 6.387S55 17.143 55 19.83V20.253c0 2.745.504 5.322 1.513 7.731a17.373 17.373 0 004.033 6.303 18.786 18.786 0 006.303 4.285c1.512.673 3.277 1.149 5.294 1.429v-6.387zM95 20v-.252c0-2.745-.504-5.322-1.513-7.731-1.008-2.465-2.38-4.594-4.117-6.387-1.68-1.792-3.754-3.193-6.219-4.201C81.751.756 80.042.28 78.025 0v6.387l1.009.336c.504.168.896.308 1.176.42a12.856 12.856 0 014.118 3.025c1.12 1.177 2.017 2.633 2.689 4.37.616 1.737.924 3.501.924 5.294V20.252c0 1.849-.308 3.642-.924 5.378-.729 1.793-1.625 3.222-2.69 4.286a11.043 11.043 0 01-4.033 2.941c-.728.336-1.485.588-2.269.757V40c1.68-.224 3.361-.7 5.042-1.429 2.297-.952 4.398-2.38 6.303-4.285 1.848-1.961 3.22-4.09 4.117-6.387A20.225 20.225 0 0095 20.17V20z"
        fill="#64ffda"
      />
    </g>
  </svg>
);

export default IconLogo;
